import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import {
  useTranslation,
  Trans,
  I18nextContext,
} from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import * as EcomaintenanceStyles from "../../styles/ecomaintenance.module.css";
import useWindowSize from "../../components/useWindowSize";
import Seo from "../../components/seo";

export default function Ecomaintenance({ data }) {
  const { t } = useTranslation();
  const context = React.useContext(I18nextContext);
  const language = context.language;
  const { width } = useWindowSize();

  function GetSphereclean(language) {
    switch (language) {
      case "fr":
        return require("../../videos/sphereclean/sphereclean_fr.mp4").default;
      case "en":
        return require("../../videos/sphereclean/sphereclean_en.mp4").default;
      case "it":
        return require("../../videos/sphereclean/sphereclean_it.mp4").default;
      case "es":
        return require("../../videos/sphereclean/sphereclean_es.mp4").default;
      case "nl":
        return require("../../videos/sphereclean/sphereclean_nl.mp4").default;
      case "de":
        return require("../../videos/sphereclean/sphereclean_de.mp4").default;
      default:
        return require("../../videos/sphereclean/sphereclean_fr.mp4").default;
    }
  }

  return (
    <Layout>
      <Seo description="Les solutions curatives adaptées. L’analyse 5gaz, en tant qu’outil de mesure de référence, a imposé un nouveau standard d’efficacité pour les additifs curatifs par des choix d’ingrédients extrêmement efficaces et utilisés à des concentrations inégalées." />
      <Container fluid className={EcomaintenanceStyles.Container}>
        <Row>
          <Col
            xs={12}
            lg={{ span: 3, offset: 1 }}
            className={EcomaintenanceStyles.ColImage}
          >
            <StaticImage
              className={EcomaintenanceStyles.GatsbyImage}
              src="../../images/savoirplus/ecomaintenance/Nos produits vs la concurrence-min.png"
              alt="eddpf"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={EcomaintenanceStyles.Titre1}>
              <Trans i18nKey="navbardetail.4">
                <sup></sup>
              </Trans>
            </h1>

            <h2 className={EcomaintenanceStyles.Titre2}>
              {t("ecosysteme.ecomaintenance.h.1")}
            </h2>
            <p className={EcomaintenanceStyles.Text}>
              <Trans i18nKey="ecosysteme.ecomaintenance.savoirplus.p.2">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
        </Row>
        {language === "fr" ? (
          width >= 992 ? (
            <Row className={EcomaintenanceStyles.Video}>
              <video loop autoPlay muted>
                <source
                  src={
                    require("../../videos/sphereclean/sphereclean_ultrawide.mp4")
                      .default
                  }
                  type="video/mp4"
                />
              </video>
            </Row>
          ) : (
            <Row className={EcomaintenanceStyles.Video}>
              <video loop autoPlay muted>
                <source
                  src={
                    require("../../videos/sphereclean/sphereclean.mp4").default
                  }
                  type="video/mp4"
                />
              </video>
            </Row>
          )
        ) : (
          <></>
        )}
        <Row className={EcomaintenanceStyles.Innovation}>
          <Col
            xs={12}
            lg={{ span: 5, offset: 1 }}
            className={EcomaintenanceStyles.InnovationCol}
          >
            <h1 className={EcomaintenanceStyles.Titre1}>
              <Trans i18nKey="ecosysteme.ecomaintenance.savoirplus.h.5">
                <sup></sup>
              </Trans>
            </h1>

            <h2 className={EcomaintenanceStyles.Titre2}>
              <Trans i18nKey="ecosysteme.ecomaintenance.savoirplus.h.6"></Trans>
            </h2>
            <p className={EcomaintenanceStyles.Text}>
              <Trans i18nKey="ecosysteme.ecomaintenance.savoirplus.p.3">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col xs={12} lg={{ span: 6 }} className="p-0">
            <video className="w-100" loop muted controls={true}>
              <source src={GetSphereclean(language)} type="video/mp4" />
            </video>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }} className="pt-3">
            <h2 className={EcomaintenanceStyles.Titre2}>
              {t("ecosysteme.ecomaintenance.savoirplus.h.2")}
            </h2>
            <p className={EcomaintenanceStyles.Text}>
              <Trans i18nKey="ecosysteme.ecomaintenance.savoirplus.p.1">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col xs={12} lg={{ span: 4, offset: 1 }} className="p-0">
            <Carousel
              className={EcomaintenanceStyles.Carousel}
              controls={false}
            >
              <Carousel.Item>
                <StaticImage
                  src={
                    "../../images/savoirplus/ecomaintenance/panne_injecteur.jpg"
                  }
                  alt="panne injecteur"
                  layout="fullWidth"
                />
                <Carousel.Caption className={EcomaintenanceStyles.Back}>
                  <h2>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext.1")}
                  </h2>
                  <p>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext2.1")}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src={
                    "../../images/savoirplus/ecomaintenance/panne_turbo2.jpg"
                  }
                  alt="panne turbo"
                  layout="fullWidth"
                />
                <Carousel.Caption className={EcomaintenanceStyles.Back}>
                  <h2>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext.2")}
                  </h2>
                  <p>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext2.1")}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src={
                    "../../images/savoirplus/ecomaintenance/panne_regulation.jpg"
                  }
                  alt="panne regulation"
                  layout="fullWidth"
                />
                <Carousel.Caption className={EcomaintenanceStyles.Back}>
                  <h2>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext.3")}
                  </h2>
                  <p>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext2.3")}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src={
                    "../../images/savoirplus/ecomaintenance/panne_admission1.jpg"
                  }
                  alt="panne admission"
                  layout="fullWidth"
                />
                <Carousel.Caption className={EcomaintenanceStyles.Back}>
                  <h2>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext.4")}
                  </h2>
                  <p>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext2.4")}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src={"../../images/savoirplus/ecomaintenance/panne_fap.jpg"}
                  alt="panne fap"
                  layout="fullWidth"
                />
                <Carousel.Caption className={EcomaintenanceStyles.Back}>
                  <h2>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext.5")}
                  </h2>
                  <p>
                    {t("ecosysteme.ecomaintenance.savoirplus.imagetext2.5")}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

// export page query
export const query = graphql`
  query Ecomaintenance($language: String!) {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
